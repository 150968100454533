import { Route, Routes } from 'react-router-dom'
import { AdvanceBorderoProvider } from './context/AdvanceBordero'
import { lazy, Suspense } from 'react'
import FullSizeLoader from './components/FullSizeLoader'
import ProtectedRoute from './components/ProtectedRoute'
import { Permissions } from './types/Permissions'
const OperationHistory = lazy(() => import('./pages/Vendor/OperationsHistory'))
const Logout = lazy(() => import('./pages/Logout'))
const AccountSettings = lazy(() => import('./pages/AccountSettings'))
const FollowDispatchDetails = lazy(() => import('./pages/Vendor/FollowDispatchDetails'))
const BatchUpload = lazy(() => import('./pages/Vendor/BatchUpload'))
const AdvanceBordero = lazy(() => import('./pages/Vendor/AdvanceBordero'))
const BorderoDetails = lazy(() => import('./pages/Vendor/BorderoDetails'))
const Taxes = lazy(() => import('./pages/Funder/Taxes'))
const VendorBatchUpload = lazy(() => import('./pages/Funder/VendorBatchUpload'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
const HomeRedirect = lazy(() => import('./pages/HomeRedirect'))
const Router = () => {
  return (
    <Suspense fallback={<FullSizeLoader withSidebar />}>
      <Routes>
        <Route
          path="/advance-bordero"
          Component={() => (
            <ProtectedRoute
              permission={[Permissions.ReadAdvanceBordero, Permissions.WriteAdvanceBordero]}
            >
              <AdvanceBorderoProvider>
                <AdvanceBordero />
              </AdvanceBorderoProvider>
            </ProtectedRoute>
          )}
        />
        <Route
          path="/advance-bordero/:uuid"
          element={
            <ProtectedRoute
              permission={[Permissions.ReadAdvanceBordero, Permissions.WriteAdvanceBordero]}
            >
              <BorderoDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/document-batch-upload"
          element={
            <ProtectedRoute permission={[Permissions.TaxDocumentUpload]}>
              <BatchUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/follow-dispatch/:uuid"
          element={
            <ProtectedRoute permission={[Permissions.ReadDocumentDispatch]}>
              <FollowDispatchDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/follow-dispatch"
          element={
            <ProtectedRoute permission={[Permissions.ReadDocumentDispatch]}>
              <OperationHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taxes"
          element={
            <ProtectedRoute permission={[Permissions.AdminReadTaxes, Permissions.AdminWriteTaxes]}>
              <Taxes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-batch-upload"
          element={
            <ProtectedRoute permission={[Permissions.AdminVendorsUpload]}>
              <VendorBatchUpload />
            </ProtectedRoute>
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/" element={<HomeRedirect />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default Router
