import { useAuthContext } from '@/context/AuthContext'
import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  permission: string[]
} & PropsWithChildren

const ProtectedRoute = ({ permission, children }: Props) => {
  const { isAuthenticated, permissions } = useAuthContext()

  if (!isAuthenticated) return <Navigate to="/logout" replace />
  if (!permissions.some((item) => permission.includes(item))) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
